import React from 'react'
import {Container} from '../components/atoms/Container'
import {Content} from '../components/atoms/Content'
import {DateRange} from '../components/atoms/DateRange'
import {Header} from '../components/atoms/Header'
import {Row} from '../components/atoms/Row'
import {SecondaryText} from '../components/atoms/SecondaryText'
import {Section} from '../components/atoms/Section'
import {Subtitle} from '../components/atoms/Subtitle'
import {ThirdRow} from '../components/atoms/ThirdRow'
import {Layout} from '../components/Layout'
import {Work} from '../components/molecules/Work'
import {RESUME} from '../assets/resume'
import {graphql, PageProps} from 'gatsby'
import {DateTime} from 'luxon'
import styled from 'styled-components'

type DataProps = {
  site: {
    buildTime: string
  }
}

const SmallComment = styled.p`
  text-align: right;
  font-size: 0.8rem;
`

const IndexPage: React.FC<PageProps<DataProps>> = ({data}) => (
  <Layout>
    <Header title={RESUME.basics.name} subtitle={RESUME.basics.label} />

    <section className="section">
      <Container>
        <Section title="Contact">
          <ThirdRow>
            {RESUME.basics.profiles.map((profile) => (
              <Content key={profile.network}>
                <Subtitle>{profile.network}</Subtitle>
                <SecondaryText>
                  <a
                    href={profile.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {profile.username}
                  </a>
                </SecondaryText>
              </Content>
            ))}
          </ThirdRow>
        </Section>

        <Section title="About">
          <p>{RESUME.basics.summary}</p>
        </Section>

        <Section title="Work">
          {RESUME.work.map((work, index) => (
            <Work {...work} key={index} />
          ))}
        </Section>

        <Section title="Education">
          {RESUME.education.map((edu, index) => (
            <Content key={index}>
              <Row>
                <Subtitle>{edu.institution}</Subtitle>
                <DateRange start={edu.startDate} end={edu.endDate} />
              </Row>
              <Row>
                <p>{edu.area}</p>
                <p>{edu.gpa}</p>
              </Row>
              <p>{edu.studyType}</p>
            </Content>
          ))}
        </Section>

        <Section title="Skills">
          {RESUME.skills.map((skill, skillIndex) => (
            <Content key={skillIndex}>
              <Subtitle>{skill.name}</Subtitle>

              <div className="tags">
                {skill.keywords.map((keyword) => (
                  <span className="tag" key={keyword}>
                    {keyword}
                  </span>
                ))}
              </div>
            </Content>
          ))}
        </Section>

        <Section title="Languages">
          <ThirdRow>
            {RESUME.languages.map((language) => (
              <Content key={language.language}>
                <Subtitle>{language.language}</Subtitle>
                <SecondaryText>{language.fluency}</SecondaryText>
              </Content>
            ))}
          </ThirdRow>
        </Section>

        <Section title="Interests">
          {RESUME.interests.map((interest) => (
            <div key={interest.name}>
              <Subtitle>{interest.name}</Subtitle>
              {interest.keywords ? (
                <ul>
                  {interest.keywords.map((keyword) => (
                    <li key={keyword}>{keyword}</li>
                  ))}
                </ul>
              ) : null}
            </div>
          ))}
        </Section>

        <SmallComment>
          {`Last updated ${DateTime.fromISO(data.site.buildTime).toLocaleString(
            DateTime.DATE_FULL
          )}`}
        </SmallComment>
      </Container>
    </section>
  </Layout>
)

export default IndexPage

export const query = graphql`
  {
    site {
      buildTime
    }
  }
`
