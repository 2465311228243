import React from 'react'
import styled from 'styled-components'
import {colors} from '../../constants/colors'
import {toMonthString} from '../../utils/dates'

const StyledDateRange = styled.div`
  color: ${colors.textSecondary};
`

type Props = {
  start: string
  end: string
}

export const DateRange = ({start, end}: Props) => (
  <StyledDateRange>
    {toMonthString(start)} - {toMonthString(end)}
  </StyledDateRange>
)
