import React from 'react'
import styled from 'styled-components'
import {Container} from './Container'

const HeaderContainer = styled.div`
  height: 300px;
  overflow: hidden;
`

const Triangle = styled.div`
  position: absolute;
  width: 100%;
  height: 300px;
  background: linear-gradient(
    0deg,
    ${(props) => props.theme.backgroundEnd},
    ${(props) => props.theme.background}
  );
  transform: skewY(-5deg);
  transform-origin: 0 0;
`

const HeaderContent = styled.div`
  position: relative;
  padding: 5rem 1.5rem;

  .title,
  .subtitle {
    color: white;
  }
`

type Props = {
  title: string
  subtitle: string
}

export const Header = ({title, subtitle}: Props) => (
  <HeaderContainer>
    <Triangle />
    <HeaderContent>
      <Container>
        <h1 className="title">{title}</h1>
        <h2 className="subtitle">{subtitle}</h2>
      </Container>
    </HeaderContent>
  </HeaderContainer>
)
