import React from 'react'
import styled from 'styled-components'
import {DateRange} from '../atoms/DateRange'
import {Row} from '../atoms/Row'

const WorkContainer = styled.div`
  margin-bottom: 5rem;
`

const Company = styled.div`
  font-weight: bold;
`

type Props = {
  company: string
  position: string
  startDate: string
  endDate: string
  website?: string
  summary: string
  highlights: string[]
}

export const Work = ({
  company,
  position,
  startDate,
  endDate,
  website,
  summary,
  highlights,
}: Props) => (
  <WorkContainer>
    <div className="content">
      <Row>
        <Company>{company}</Company>
        <DateRange start={startDate} end={endDate} />
      </Row>

      <Row>
        <p>{position}</p>
        {website && <a href={website}>{website}</a>}
      </Row>

      <p>{summary}</p>
      {highlights.length ? (
        <div>
          <b>Highlights</b>
          <ul>
            {highlights.map((highlight, j) => (
              <li key={j}>{highlight}</li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  </WorkContainer>
)
