import React from 'react'
import styled from 'styled-components'
import {Title} from './Title'

const SectionContainer = styled.div`
  margin-bottom: 3rem;
`

type Props = {
  title: string
}

export const Section: React.FC<Props> = ({title, children}) => (
  <SectionContainer>
    <Title>{title}</Title>
    {children}
  </SectionContainer>
)
