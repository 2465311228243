import styled from 'styled-components'
import {BREAKPOINTS} from '../../constants/breakpoints'

export const ThirdRow = styled.div`
  @media (min-width: ${BREAKPOINTS.TABLET}em) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      width: 32%;
    }
  }
`
