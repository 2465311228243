export const RESUME = {
  basics: {
    name: 'Harold Waterkeyn',
    label: 'Senior Full Stack Software Engineer',
    picture: './pic.jpg',
    email: 'harold.waterkeyn@gmail.com',
    phone: '+32 472 57 53 35',
    summary:
      'Passionate engineer, I love tackling hard problems and find solutions in a timely manner. Natural team player, I like sharing my experience, mentoring and leading a team, keeping people highly motivated by empowering them. Next to my work activities, I contribute to open source, work on personal projects and go to conferences and meetups in order to fulfil my never ending curiosity and thirst for knowledge.',
    location: {
      address: '9 rue Moris',
      postalCode: '1060',
      city: 'Brussels',
      countryCode: 'BE',
    },
    profiles: [
      {
        network: 'LinkedIn',
        username: 'Harold Waterkeyn',
        url: 'https://www.linkedin.com/in/harold-waterkeyn-60873754',
      },
      {
        network: 'Github',
        username: 'hwaterke',
        url: 'https://github.com/hwaterke',
      },
    ],
  },
  work: [
    {
      company: 'Shippr',
      position: 'CTO',
      website: 'https://shippr.io',
      startDate: '2018-06-08',
      endDate: 'Now',
      summary:
        "As Shippr's CTO, I am responsible for all tech related aspects of the company. Leading the engineering team by example, I make sure the product evolves according to our customers needs. By adopting a lean approach I make sure the team focuses on what brings the most value at any given time while paving for the future.",
      highlights: [
        'Architect',
        'Product owner',
        'Scrum master',
        "Engineer's recruitment",
        'Business analyst, challenge and translate business requirements to technical designs',
        'People management (121s, setting objectives)',
        'react, react-native, Node.js, GraphQL, PostgreSQL, docker, aws, jenkins',
      ],
    },
    {
      company: 'Shippr',
      position: 'Full Stack developer and architect (Freelancer)',
      website: 'https://shippr.io',
      startDate: '2017-10-07',
      endDate: '2018-06-08',
      summary:
        'I designed and implemented the foundation and first version of the Shippr platform.',
      highlights: [
        'Developed the backend in Node.js',
        'Developed the Customer dashboard (react)',
        'Developed the mobile application in react-native',
        'Developed the admin dashboard for the operations team',
        'Setup and managed the infrastructure and deployments on AWS',
        'Interviewed and onboarded interns',
      ],
    },
    {
      company: 'BIL (via Hackages)',
      position: 'React-native expert and coach',
      website: 'https://bil.lu',
      startDate: '2017-12-01',
      endDate: '2018-03-07',
      summary:
        'Consultant (via Hackages) - As a react-native coach, I helped the team at the Banque Internationale à Luxembourg to develop their new mobile experience using the best practices and latest recommended techniques.',
      highlights: [
        'Coach best practices in react and react-native',
        'Storage setup for the app',
        'Architecture layout for handling server calls throughout the application',
        'Security audit recommendations',
        'rn, redux, redux-thunk, redux-persist, sensitive-storage',
      ],
    },
    {
      company: 'Hackages',
      position: 'Lead Open Source Engineer',
      website: 'https://hackages.io',
      startDate: '2017-04-21',
      endDate: '2018-06-08',
      summary:
        'As a lead open source engineer at Hackages, I lead the team of 5 engineers on all engineering projects. My everyday goal is to make sure we deliver on time and with great quality. I also ensure that the engineers stay motivated and are constantly learning new things. I still take an very active role in the development, leading by example and making sure the team follows the best practices.',
      highlights: [
        'Lead the development team to build the Hackages platform (React, Redux, GraphQL, Node)',
        'Scrum Master for the engineering team',
        'Designed and built a Netflix clone in React as material for a training I then gave',
        'Designed and built a Uber clone in React Native as material for a training I then gave',
        'Handled the operational aspects of the platform: Continuous Deployment on AWS and Docker Swarm configuration',
        'Designed and taught best practices on the latest web technologies during Meetups around europe (Amsterdam, Dublin, Paris)',
        'Interviewed new engineers to join the team',
      ],
    },
    {
      company: 'National Bank of Belgium (via Hackages)',
      position: 'Senior Full Stack Developer (Java/Angular)',
      website: 'https://nbb.be',
      startDate: '2017-01-02',
      endDate: '2017-04-21',
      summary:
        'Consultant (via Hackages) - As a lead developer I helped the team at the National Bank to start building the future of their Cash management platform sold to many national banks around Europe.',
      highlights: [
        'Trained the team (6 people) to be up to date with the different technologies',
        'Designed a framework to ease the development for newcomers',
        'Angular',
        'RxJS',
        'ngrx',
        'REST APIs',
        'Protractor end-to-end testing',
      ],
    },
    {
      company: 'Hackages',
      position: 'Open Source Engineer',
      website: 'https://hackages.io',
      startDate: '2016-10-31',
      endDate: '2017-04-21',
      summary:
        'As an open source engineer, I researched and contributed to the latest frontend technologies. With this in depth expertise I produced and provided high quality trainings on the latest JavaScript technologies and frameworks',
      highlights: [
        'R&D on the latest frontend technologies (React, Angular, Redux)',
        'Contributed to the open source world around those technologies',
        'Designed and taught advanced Javascript trainings (ES6)',
        'Designed and taught React trainings (redux, react-routing, higher order components)',
        'Designed and taught RxJS trainings (operators, subjects)',
        'Designed and taught Angular trainings (routing, http service)',
      ],
    },
    {
      company: 'SWIFT',
      position: 'Dev. / System Engineer',
      website: 'https://swift.com',
      startDate: '2015-03-01',
      endDate: '2016-10-28',
      summary:
        'Designing and prototyping a distributed model for financial message processing using bleeding edge technologies.',
      highlights: [
        'Java 1.8',
        'Shared nothing architecture',
        'Commit log (Apache Kafka & Apache ZooKeeper)',
        'Log aggregation with the Elastic Stack (ELK)',
        'REST APIs',
        'Docker deployment',
        'CI setup with Jenkins',
      ],
    },
    {
      company: 'SWIFT',
      position: 'Associate Dev. / System Engineer',
      website: 'https://swift.com',
      startDate: '2013-09-01',
      endDate: '2015-03-01',
      summary:
        'Participated in the design and implementation of a financial confirmation matching service.',
      highlights: [
        'Java 1.7',
        'OSGI Container (Jboss Fuse)',
        'Enterprise Integration Patterns (Camel)',
        'JPA (EclipseLink)',
        'Messaging systems (ActiveMQ, OracleAQ)',
        'Guice, JPA (EclipseLink), EMF, JAXB',
        'BPMN Platform (Activiti)',
        'Oracle 11g',
      ],
    },
    {
      company: 'IBM',
      position: 'Extreme Blue Internship',
      website: 'https://www.ibm.com/extremeblue',
      startDate: '2012-07-01',
      endDate: '2012-09-21',
      summary:
        "The Extreme Blue Internship is IBM's premier internship program for top notch students pursuing a technical or business degree.",
      highlights: [
        'Found an innovative solution to a challenge.',
        'Worked for a real client of IBM (telecommunication sector).',
        'Dived into the Business/IT multidisciplinary aspect by building a prototype of the solution and writting a business case.',
        'Worked in a multidisciplinary (business/technical) international team of 4 students.',
        "Presented the project in international conferences: Extreme Blue Europe - IBM Innovation Center - La gaude (FR) and IBM Inspire Beyond Today's Technology - IBM Amsterdam",
      ],
    },
    {
      company: 'CAMI: Apple Reseller, Brussels.',
      position: 'Student Job',
      startDate: '2011-08-01',
      endDate: '2011-07-01',
      summary:
        'Seller and adviser in computer hardware, software and technologies.',
      highlights: [],
    },
  ],
  education: [
    {
      institution: 'Université Libre de Bruxelles',
      area: 'Engineering',
      studyType: 'Master',
      startDate: '2011-09-01',
      endDate: '2013-07-01',
      gpa: 'Grande distinction',
    },
    {
      institution: 'Université Libre de Bruxelles',
      area: 'Computer Science',
      studyType: 'Master',
      startDate: '2011-09-01',
      endDate: '2013-07-01',
      gpa: 'Grande distinction',
    },
    {
      institution: 'Université Libre de Bruxelles',
      area: 'Computer Science',
      studyType: 'Bachelor',
      startDate: '2008-09-01',
      endDate: '2011-07-01',
      gpa: 'La plus grande distinction',
    },
    {
      institution: 'Notre Dame des Champs',
      area: 'General Secondary Education',
      studyType: 'Mathematics, Science, English and Latin',
      startDate: '2001-09-01',
      endDate: '2007-07-01',
    },
  ],
  skills: [
    {
      name: 'Languages',
      keywords: [
        'JavaScript/Typescript/Node.js',
        'Java',
        'Kotlin',
        'Ruby',
        'Python',
      ],
    },
    {
      name: 'Frontend',
      keywords: [
        'ES6',
        'React',
        'Gatsby',
        'React Native',
        'Redux',
        'GraphQL',
        'Angular 5',
        'RxJS',
        'TypeScript / Flowtype',
        'HTML 5 & CSS',
        'Testing: Jest, Mocha, Jasmine, Karma, Protractor, Cypress',
      ],
    },
    {
      name: 'Backend',
      keywords: [
        'Node.js (Express, ...)',
        'Spring',
        'REST APIs',
        'GraphQL',
        'SQL Databases (Oracle, PostgreSQL, MySQL)',
        'NoSQL Databases (MongoDB)',
      ],
    },
    {
      name: 'DevOps/Infra',
      keywords: [
        'Docker',
        'Docker Swarm',
        'Kubernetes',
        'AWS (EC2, RDS, S3, ECR, KMS)',
        'Jenkins',
        'Circle CI',
        'Continuous Integration',
        'Continuous Deployment',
      ],
    },
    {
      name: 'Tooling',
      keywords: ['Git', 'Maven', 'Gradle', 'npm/yarn', 'Webpack'],
    },
  ],
  languages: [
    {
      language: 'French',
      fluency: 'Native speaker',
    },
    {
      language: 'English',
      fluency: 'Fluent',
    },
  ],
  interests: [
    {
      name: 'Technologies',
      keywords: [
        'Web development',
        'Arduino / Raspberry Pi',
        'Home automation',
      ],
    },
    {
      name: 'Fitness',
    },
  ],
}
